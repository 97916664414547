import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';

import OverallBalance from './OverallBalance';
import GreetingCard from './GreetingCard';
import RecentOrders from './RecentOrders';
import Widgets1 from '../../Common/CommonWidgets/Widgets1';
import Request from '../../../api/httpClient';
import { toast } from 'react-toastify';
import MixedChartClass from '../../Charts/ApexCharts/MixedChart';
const request = new Request();

const Dashboard = () => {
  const [infoWidgets, setInfoWidgets] = useState([
    {
      id: 1,
      title: '...',
      gros: 50,
      total: 0,
      color: 'secondary',
      icon: 'fill-task',
    },
    {
      id: 1,
      title: '...',
      gros: 20,
      total: 0,
      color: 'warning',
      icon: 'fill-task',
    },
    {
      id: 1,
      title: '...',
      gros: 70,
      total: 0,
      color: 'primary',
      icon: 'fill-task',
    },
    {
      id: 1,
      title: '...',
      gros: 70,
      total: 0,
      color: 'success',
      icon: 'fill-task',
    },
    {
      id: 1,
      title: '...',
      gros: 70,
      total: 0,
      color: 'secondary',
      icon: 'fill-task',
    },
    {
      id: 1,
      title: '...',
      gros: 70,
      total: 0,
      color: 'warning',
      icon: 'fill-task',
    },
  ]);
  const coloresWidgets = [
    'secondary',
    'warning',
    'primary',
    'success',
    'secondary',
    'warning',
    'primary',
    'success',
  ];

  const [ventas, setVentas] = useState([
    {
      name: 'Agente',
      data: [],
    },
    {
      name: 'Tienda',
      data: [],
    },
  ]);

  const [ventasBalance, setVentasBalance] = useState([
    {
      name: 'Mes actual',
      type: 'column',
      data: [],
    },
    {
      name: 'Mes anterior',
      type: 'area',
      data: [],
    },
    {
      name: 'Promedio cuota',
      type: 'line',
      data: [],
    },
    {
      name: 'Promedio venta',
      type: 'line',
      data: [],
    },
  ]);
  const [userTypeID, setUserTypeID] = useState(localStorage.getItem('TypeID'));

  const [tipoInfo, setTipoInfo] = useState(0); //0-masivo, 1-empresarial
  const [widgetActivo, setWidgetActivo] = useState('Ventas');
  const [loadingWidgetInfo, setLoadingWidgetInfo] = useState(false);
  const empleadoSeleccionado = JSON.parse(localStorage.empleado_selec);

  //obtener la informacion de los widgets
  const getWidgetsInfo = async () => {
    let sucursalInfo = null;

    if (JSON.parse(localStorage.sucursal_selec) !== null) {
      if (localStorage.getItem('SucursalesInfo') == 0) {
        sucursalInfo = +JSON.parse(localStorage.sucursal_selec).value;
      }
    }

    let data = {
      tipo: tipoInfo,
      id_usuario: localStorage.getItem('user_id') || 0,
      id_sucursal: sucursalInfo,
      id_empleado:
        JSON.parse(localStorage.empleado_selec) !== null
          ? +JSON.parse(localStorage.empleado_selec).value
          : null,
    };

    const response = await request.post('/users/get/info/', data);
    if (response && !response.error) {
      if (response.info && !response.empty) {
        const info = response.info.map((info, i) => ({
          id: info.Orden,
          title: info.nombre,
          gros: 70,
          total: info.cantidad,
          color: coloresWidgets[i],
          icon: 'fill-task',
        }));
        setInfoWidgets(info);
      } else {
        toast.error(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      }
    } else {
      toast.error(response.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    }
  };

  //obtener la informacion para el widget seleccionado
  const getSelectedWidgetInfo = async () => {
    let sucursalInfo = null;
    if (JSON.parse(localStorage.sucursal_selec) !== null) {
      if (localStorage.getItem('SucursalesInfo') == 0) {
        sucursalInfo = +JSON.parse(localStorage.sucursal_selec).value;
      }
    }

    let data = {
      tipo: tipoInfo,
      grupo: widgetActivo.substring(0, 3).toUpperCase(),
      id_usuario: localStorage.getItem('user_id') || 0,
      id_sucursal: sucursalInfo,
      id_empleado:
        JSON.parse(localStorage.empleado_selec) !== null
          ? +JSON.parse(localStorage.empleado_selec).value
          : null,
    };
    let url =
      userTypeID === '0' || empleadoSeleccionado !== null
        ? '/users/get/info/widget'
        : '/users/get/info/widgetBalance';

    // si no hay ningun widget activo, entonces muestro las ventas totales
    if (widgetActivo == 'Ventas') {
      data = {
        tipo: tipoInfo,
        id_usuario: localStorage.getItem('user_id') || 0,
        id_sucursal: sucursalInfo,
        id_empleado:
          JSON.parse(localStorage.empleado_selec) !== null
            ? +JSON.parse(localStorage.empleado_selec).value
            : null,
      };

      url =
        userTypeID === '0' || empleadoSeleccionado !== null
          ? '/users/get/info/ventas'
          : '/users/get/info/ventasBalance';
    }
    setLoadingWidgetInfo(true);

    const response = await request.post(url, data);
    if (response && !response.error) {
      if (response.ventas && !response.empty) {
        if (userTypeID === '0' || empleadoSeleccionado !== null) {
          const ventas = [
            { name: 'Agente', data: [] },
            { name: 'Tienda', data: [] },
          ];

          response.ventas.forEach(element => {
            ventas[0].data.push(element.AGENTE);
            ventas[1].data.push(element.TIENDA);
          });

          setVentas(ventas);
        } else {
          const ventas = [
            { name: 'Mes actual', type: 'column', data: [] },
            { name: 'Mes anterior', type: 'area', data: [] },
            { name: 'Promedio cuota', type: 'line', data: [] },
            { name: 'Promedio venta', type: 'line', data: [] },
          ];

          response.ventas.forEach(element => {
            ventas[0].data.push(element.TIENDA_ACTUAL);
            ventas[1].data.push(element.TIENDA_ANTERIOR);
            ventas[2].data.push(element.CUOTA);
            ventas[3].data.push(element.PROMEDIO_VENTA);
          });
          setVentasBalance(ventas);
        }
      } else {
        toast.error(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      }
    } else {
      toast.error(response.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    }
    setLoadingWidgetInfo(false);
  };

  useEffect(() => {
    getWidgetsInfo();
    getSelectedWidgetInfo();
  }, [tipoInfo, widgetActivo]);

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Dashboard" parent="General" title="Tablero" />
      <Container fluid={true}>
        <Row className="widget-grid">
          <GreetingCard tipoInfo={tipoInfo} setTipoInfo={setTipoInfo} />
          {infoWidgets.map((item, i) => (
            <Col key={i} xxl="auto" xl="3" sm="6" className="box-col-6">
              <Row>
                {i * 2 < infoWidgets.length && infoWidgets[i * 2].id !== 7 && (
                  <Col xl="12">
                    <Widgets1
                      data={infoWidgets[i * 2]}
                      setWidgetActivo={setWidgetActivo}
                      getSelectedWidgetInfo={getSelectedWidgetInfo}
                      tipoInfo={tipoInfo}
                    />
                  </Col>
                )}
                {i * 2 + 1 < infoWidgets.length &&
                  infoWidgets[i * 2 + 1].id !== 7 && (
                    <Col xl="12">
                      <Widgets1
                        data={infoWidgets[i * 2 + 1]}
                        setWidgetActivo={setWidgetActivo}
                        getSelectedWidgetInfo={getSelectedWidgetInfo}
                        tipoInfo={tipoInfo}
                      />
                    </Col>
                  )}
              </Row>
            </Col>
          ))}

          {userTypeID === '0' || empleadoSeleccionado !== null ? (
            //se muestra solo a los agentes (ejecutivos)
            <OverallBalance
              ventas={ventas}
              titulo={widgetActivo}
              loadingWidgetInfo={loadingWidgetInfo}
              setWidgetActivo={setWidgetActivo}
            />
          ) : (
            //se muestra solo a los empleaados (gerentes ya admin)
            <MixedChartClass
              titulo={widgetActivo}
              ventasBalance={ventasBalance}
              loadingWidgetInfo={loadingWidgetInfo}
            />
          )}
          <RecentOrders
            nombre={[infoWidgets[infoWidgets.length - 1].title]}
            valor={[infoWidgets[infoWidgets.length - 1].total]}
          />
        </Row>
      </Container>
    </Fragment>
  );
};

export default Dashboard;
