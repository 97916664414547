import React, {
  Fragment,
  useState,
  useLayoutEffect,
  useContext,
  useEffect,
} from 'react';
import { Col } from 'reactstrap';
import { AlignCenter } from 'react-feather';
import { Link } from 'react-router-dom';
import { H6, Image } from '../../../AbstractElements';
import CustomizerContext from '../../../_helper/Customizer';
import { padding } from '@mui/system';

const Leftbar = () => {
  const { layoutURL, toggleSidebar } = useContext(CustomizerContext);
  const [sidebartoggle, setSidebartoggle] = useState(true);
  const width = useWindowSize();

  function useWindowSize() {
    //temporal - borrar en el proximo deploy
    if (localStorage.getItem('empleado_selec') === null) {
      localStorage.setItem('empleado_selec', null);
    }
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
        if (window.innerWidth <= 1007) {
          toggleSidebar(true);
          document.querySelector('.page-wrapper').className =
            'page-wrapper compact-wrapper';
        } else {
          toggleSidebar(false);
        }
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  const responsive_openCloseSidebar = toggle => {
    if (width <= 991) {
      document.querySelector('.page-header').className = 'page-header';
      document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper ';
      document.querySelector('.bg-overlay').classList.add('active');
    } else {
      if (toggle) {
        setSidebartoggle(!toggle);
        document.querySelector('.page-header').className =
          'page-header close_icon';
        document.querySelector('.sidebar-wrapper').className =
          'sidebar-wrapper close_icon ';
        document
          .querySelector('.mega-menu-container')
          .classList.remove('d-block');
      } else {
        setSidebartoggle(!toggle);
        document.querySelector('.page-header').className = 'page-header';
        document.querySelector('.sidebar-wrapper').className =
          'sidebar-wrapper ';
      }
    }
  };

  return (
    <Fragment>
      <Col className="header-logo-wrapper col-auto p-0" id="out_side_click">
        <div className="logo-wrapper">
          <Link to={'/'}>
            <Image
              attrImage={{
                className: 'img-fluid for-light',
                src: `${require('../../../assets/images/logo/logo.png')}`,
                alt: '',
              }}
            />
            <Image
              attrImage={{
                className: 'img-fluid for-dark',
                src: `${require('../../../assets/images/logo/logo_dark.png')}`,
                alt: '',
              }}
            />
          </Link>
        </div>
        <div
          className="toggle-sidebar"
          onClick={() => responsive_openCloseSidebar(sidebartoggle)}
          style={
            window.innerWidth <= 991
              ? { display: 'block' }
              : { display: 'none' }
          }
        >
          <AlignCenter
            className="status_toggle middle sidebar-toggle"
            id="sidebar-toggle"
          />
        </div>
      </Col>
      <Col xxl="5" xl="6" lg="5" md="4" sm="3" className="left-header p-0">
        {/*  <NotificationSlider /> */}
        <div className="d-flex h-100" style={{ alignItems: 'flex-end' }}>
          <i
            className="icofont icofont-building-alt"
            style={{ fontSize: '15px', color: '#4069d0', padding: '10px' }}
          ></i>
          <H6 attrH6={{ className: 'mb-0 f-w-400' }}>
            <span className="f-light">
              Sucursal:{' '}
              {JSON.parse(localStorage.sucursal_selec) !== null &&
              localStorage.getItem('SucursalesInfo') == 0
                ? JSON.parse(localStorage.sucursal_selec).label
                : 'Todas'}
            </span>
          </H6>
          {JSON.parse(localStorage.empleado_selec) !== null && (
            <>
              <i
                className="icofont icofont-user-alt-2"
                style={{
                  fontSize: '15px',
                  color: '#4069d0',
                  padding: '10px 10px 10px 30px',
                }}
              ></i>

              <H6 attrH6={{ className: 'mb-0 f-w-400' }}>
                <span className="f-light">
                  Empleado:{' '}
                  {JSON.parse(localStorage.empleado_selec)
                    .label.split(' ')
                    .slice(0, 2)
                    .join(' ')}
                </span>
              </H6>
            </>
          )}
        </div>
      </Col>
    </Fragment>
  );
};

export default Leftbar;
