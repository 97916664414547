import React, { Fragment, useContext } from 'react';
import sad from '../../../assets/images/other-images/sad.png';
import { Link } from 'react-router-dom';
import { Container, Button, Media, Col } from 'reactstrap';
import { BACK_TO_HOME_PAGE } from '../../../Constant';
import CustomizerContext from '../../../_helper/Customizer';
import { H1, P } from '../../../AbstractElements';

const Error404 = () => {
  const { layoutURL } = useContext(CustomizerContext);
  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="error-wrapper">
          <Container>
            <Media body className="img-100" src={sad} alt="" />
            <div className="error-heading">
              <H1 attrH1={{ className: ' font-danger' }}>{'404'}</H1>
            </div>
            <Col md="8 offset-md-2">
              <P attrPara={{ className: 'sub-content' }}>
                {
                  'La página a la que intenta acceder no está disponible actualmente. Esto puede deberse a que la página no existe o se ha movido.'
                }
              </P>
            </Col>
            <Link to={'/'}>
              <Button color="danger-gradien" size="lg">
                VOLVER A LA PÁGINA DE INICIO
              </Button>
            </Link>
          </Container>
        </div>
      </div>
    </Fragment>
  );
};

export default Error404;
