import React, { Fragment, useCallback, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Image, P } from '../../../AbstractElements';

import search from '../../../assets/images/search-not-found.png';

const CustomLoader = () => (
  <div className="loader-box">
    <div className="loader-15" />
  </div>
);

const DataTableComponent = props => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [data, setData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleDelete = () => {
    if (
      window.confirm(
        `Are you sure you want to delete:\r ${selectedRows.map(r => r.title)}?`
      )
    ) {
      setToggleDelet(!toggleDelet);

      setData(
        data.filter(item =>
          selectedRows.filter(elem => elem.id === item.id).length > 0
            ? false
            : true
        )
      );
      setSelectedRows('');
    }
  };

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const CustomNoDataComponent = () => (
    <div className="search-not-found text-center">
      <div>
        <Image
          attrImage={{ src: `${search}`, alt: '', className: 'second-search' }}
        />
        <P attrPara={{ className: 'mb-2' }}>{'No se encontraron datos'}</P>
      </div>
    </div>
  );

  return (
    <Fragment>
      <DataTable
        data={props.tableRows}
        columns={props.tableColumns}
        striped={true}
        center={true}
        fixedHeader
        fixedHeaderScrollHeight="500px"
        pagination={props.pagination !== undefined ? props.pagination : true}
        paginationComponentOptions={paginationComponentOptions}
        // selectableRows
        progressPending={props.loadingVentas}
        progressComponent={<CustomLoader />}
        noDataComponent={<CustomNoDataComponent />}
        conditionalRowStyles={props.conditionalRowStyles}
      />
    </Fragment>
  );
};
export default DataTableComponent;
