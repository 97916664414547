import React, { Fragment, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Breadcrumbs, Btn, H6 } from '../../AbstractElements';
import Request from '../../api/httpClient';
import { Container, Row, Col, Card, CardBody, Label } from 'reactstrap';
import HeaderCard from '../Common/Component/HeaderCard';
import { format } from 'date-fns';
import DataTableComponent from '../Tables/DataTable/DataTableComponent';
import moment from 'moment';
import { saveAs } from 'file-saver';
const request = new Request();
const ExcelJS = require('exceljs');

const Reportes = () => {
  const [periodo_errores, setPeriodoErrores] = useState(new Date());
  const [periodo_comisiones, setPeriodoComisiones] = useState(new Date());
  const [periodo_ventas, setPeriodoVentas] = useState(new Date());
  const [errores, setErrores] = useState([]);
  const [comisiones, setComisiones] = useState([]);
  const [comisionesDetalles, setComisionesDetalles] = useState([]);
  const [ventas, setVentas] = useState([]);
  const [loadingErrores, setLoadingErrores] = useState(false);
  const [loadingComisiones, setLoadingComisiones] = useState(false);
  const [loadingVentas, setLoadingVentas] = useState(false);
  const [loadingComisionesDetalles, setLoadingComisionesDetalles] =
    useState(false);
  const [comisionesTableData, setComisionesTableData] = useState([]);

  const tableColumns_errores = [
    {
      name: 'CO_ID (IMEI)',
      minWidth: '160px',
      width: 25,
      selector: row => `${row.CODID}`,
      sortable: true,
      center: false,
    },
    {
      name: 'Tipo',
      width: 15,
      selector: row => `${row.Tipo}`,
      sortable: true,
      center: false,
    },
    {
      name: 'Agente',
      width: 40,
      minWidth: '300px',
      selector: row => `${row.Agente}`,
      sortable: true,
      center: false,
    },
    {
      name: 'Fecha',
      width: 15,
      minWidth: '150px',
      selector: row => format(new Date(row.Fecha), 'dd-MM-yyyy'),
      sortable: true,
      center: false,
    },
    {
      name: 'Comentarios',
      width: 40,
      minWidth: '400px',
      selector: row => `${row.MOTIVO}`,
      sortable: true,
      center: false,
    },
  ];

  const tableColumns_comisiones = [
    {
      name: 'CO_ID (IMEI)',
      minWidth: '200px',
      width: 25,
      selector: row => `${row.codid}`,
      sortable: true,
      center: false,
    },
    {
      name: 'Tipo',
      minWidth: '151px',
      width: 16,
      selector: row => `${row.TipoVenta}`,
      sortable: true,
      center: false,
    },
    {
      name: 'Producto',
      minWidth: '340px',
      width: 37,
      selector: row => `${row.producto}`,
      sortable: true,
      center: false,
    },
    {
      name: 'Fecha',
      minWidth: '151px',
      width: 16,
      selector: row => format(new Date(row.Fecha), 'dd-MM-yyyy'),
      sortable: true,
      center: false,
    },
  ];

  const tableColumns_ventas = [
    {
      name: 'CO_ID (IMEI)',
      minWidth: '200px',
      width: 25,
      selector: row => `${row.CodID}`,
      sortable: true,
      center: false,
    },
    {
      name: 'Tipo',
      minWidth: '151px',
      width: 16,
      selector: row => `${row.Tipo}`,
      sortable: true,
      center: false,
    },
    {
      name: 'Producto',
      minWidth: '340px',
      width: 37,
      selector: row => `${row.Marca}`,
      sortable: true,
      center: false,
    },
    {
      name: 'Fecha',
      minWidth: '151px',
      width: 16,
      selector: row => format(new Date(row.Fecha), 'dd-MM-yyyy'),
      sortable: true,
      center: false,
    },
  ];

  const tableColumns_comisiones_ventas = [
    {
      name: (
        <div style={{ width: '50%', textAlign: 'right' }}>Activaciones</div>
      ),
      excelname: 'Activaciones',
      minWidth: '200px',
      width: 15,
      selector: row => `${row.num_act}`,
      cell: row => (
        <div style={{ width: '50%', textAlign: 'right' }}>{row.num_act}</div>
      ),
      sortable: true,
      center: false,
      numFmt: '#,##0',
    },
    {
      name: (
        <div style={{ width: '50%', textAlign: 'right' }}>Renovaciones</div>
      ),
      excelname: 'Renovaciones',
      minWidth: '210px',
      width: 15,
      selector: row => `${row.num_ren}`,
      cell: row => (
        <div style={{ width: '50%', textAlign: 'right' }}>{row.num_ren}</div>
      ),
      sortable: true,
      center: false,
      numFmt: '#,##0',
    },
    {
      name: <div style={{ width: '50%', textAlign: 'right' }}>Addons</div>,
      excelname: 'Addons',
      minWidth: '150px',
      width: 15,
      selector: row => `${row.num_add}`,
      cell: row => (
        <div style={{ width: '50%', textAlign: 'right' }}>{row.num_add}</div>
      ),
      sortable: true,
      center: false,
      numFmt: '#,##0',
    },
    {
      name: <div style={{ width: '50%', textAlign: 'right' }}>Prepagos</div>,
      excelname: 'Prepagos',
      minWidth: '150px',
      width: 15,
      selector: row => `${row.num_pre}`,
      cell: row => (
        <div style={{ width: '50%', textAlign: 'right' }}>{row.num_pre}</div>
      ),
      sortable: true,
      center: false,
      numFmt: '#,##0',
    },
  ];

  const tableColumns_comisiones_comisiones = [
    {
      name: (
        <div style={{ width: '50%', textAlign: 'right' }}>Activaciones</div>
      ),
      excelname: 'Activaciones',
      minWidth: '200px',
      width: 15,
      selector: row => `${row.com_act}`,
      cell: row => (
        <div style={{ width: '50%', textAlign: 'right' }}>{`${parseFloat(
          row.com_act
        ).toLocaleString('es-MX', {
          style: 'currency',
          currency: 'MXN',
        })}`}</div>
      ),
      sortable: true,
      center: false,
      numFmt: '#,##0.00',
    },
    {
      name: (
        <div style={{ width: '50%', textAlign: 'right' }}>Renovaciones</div>
      ),
      excelname: 'Renovaciones',
      minWidth: '210px',
      width: 15,
      selector: row => `${row.com_ren}`,
      cell: row => (
        <div style={{ width: '50%', textAlign: 'right' }}>{`${parseFloat(
          row.com_ren
        ).toLocaleString('es-MX', {
          style: 'currency',
          currency: 'MXN',
        })}`}</div>
      ),
      sortable: true,
      center: false,
      numFmt: '#,##0.00',
    },
    {
      name: <div style={{ width: '50%', textAlign: 'right' }}>Addons</div>,
      excelname: 'Addons',
      minWidth: '150px',
      width: 15,
      selector: row => `${row.com_add}`,
      cell: row => (
        <div style={{ width: '50%', textAlign: 'right' }}>{`${parseFloat(
          row.com_add
        ).toLocaleString('es-MX', {
          style: 'currency',
          currency: 'MXN',
        })}`}</div>
      ),
      sortable: true,
      center: false,
      numFmt: '#,##0.00',
    },
    {
      name: <div style={{ width: '50%', textAlign: 'right' }}>Prepagos</div>,
      excelname: 'Prepagos',
      minWidth: '150px',
      width: '15',
      selector: row => `${row.com_pre}`,
      cell: row => (
        <div style={{ width: '50%', textAlign: 'right' }}>{`${parseFloat(
          row.com_pre
        ).toLocaleString('es-MX', {
          style: 'currency',
          currency: 'MXN',
        })}`}</div>
      ),
      sortable: true,
      center: false,
      numFmt: '#,##0.00',
    },
  ];

  const columnas_comisiones_resumen = {
    prc_com: '% Comisiones',
    prc_pre: '% Prepagos',
    puntos: 'Puntos',
    bono: 'Bonos',
    bonificacion: 'Bonificación',
    penalizacion: '% Penalización',
    REINTEGRO: 'Reingreso',
    CHARGEBACK: 'Chargeback',
  };

  const tableColumns_comisiones_resumen = [
    {
      selector: row => row.columna1,
      minWidth: '100px',
      width: 15,
      sortable: true,
      center: false,
    },
    {
      minWidth: '50px',
      width: 15,
      sortable: true,
      center: false,
      selector: row => `${row.columna2}`,
      cell: row =>
        row.columna1 === 'Puntos' ||
        row.columna1 === 'Bonos' ||
        row.columna1 === 'Chargeback' ? (
          <div style={{ width: '50%', textAlign: 'right' }}>{`${parseFloat(
            row.columna2
          ).toLocaleString('es-MX', {
            style: 'currency',
            currency: 'MXN',
          })}`}</div>
        ) : (
          <div style={{ width: '50%', textAlign: 'right' }}>{row.columna2}</div>
        ),
    },
  ];

  /* const exportToExcel = (tableColumns, tableRows, filename) => {
    // Crear un nuevo libro de trabajo y una hoja de trabajo
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(filename);

    // Agregar encabezados de columna
    const headers = tableColumns.map(column => column.name);
    worksheet.addRow(headers);

    // Define column headers
    const columnHeaders = tableColumns.map(column => ({
      header: column.name,
      key: column.name,
      width: column.width,
      style: { numFmt: column.numFmt },
    }));

    worksheet.columns = columnHeaders;

    // Poner en negrita los encabezados de columna
    // Cambiar el color de fondo de los encabezados de columna
    tableColumns.forEach((column, index) => {
      worksheet.getCell(1, index + 1).font = {
        bold: true,
        size: 12,
        color: { argb: 'FFFFFFFF' },
      };
      worksheet.getCell(1, index + 1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '16c7f9' },
      };
    });

    // Agregar filas de datos
    tableRows.forEach(row => {
      const rowData = tableColumns.map(column => {
        const value = column.selector(row);
        if (column.numFmt && column.numFmt !== '@') {
          return Number(value);
        }
        return value;
      });
      worksheet.addRow(rowData);
    });

    // Add black borders around all cells starting from the second row
    worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
      if (rowNumber > 1) {
        row.eachCell({ includeEmpty: true }, cell => {
          cell.border = {
            top: { style: 'thin', color: { argb: 'FF000000' } },
            left: { style: 'thin', color: { argb: 'FF000000' } },
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
            right: { style: 'thin', color: { argb: 'FF000000' } },
          };
        });
      } else {
        worksheet.getRow(1).eachCell({ includeEmpty: true }, cell => {
          cell.border = {
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
          };
        });
      }
    });

    // Generar el archivo de Excel y descargarlo
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, filename + ".xlsx");
    });
  }; */

  const exportToExcel = (tables, filename) => {
    // Crear un nuevo libro de trabajo
    const workbook = new ExcelJS.Workbook();

    // Iterar sobre cada tabla
    tables.forEach((table, index) => {
      const { tableColumns, tableRows } = table;

      // Agregar una nueva hoja de trabajo para cada tabla
      const worksheet = workbook.addWorksheet(`${filename}_${index + 1}`);

      // Agregar encabezados de columna
      const headers = tableColumns.map(
        column => column.excelname || column.name
      );
      worksheet.addRow(headers);

      // Define column headers
      const columnHeaders = tableColumns.map(column => ({
        header: column.excelname || column.name,
        key: column.excelname || column.name,
        width: column.width,
        style: { numFmt: column.numFmt },
      }));

      worksheet.columns = columnHeaders;

      // Poner en negrita los encabezados de columna
      // Cambiar el color de fondo de los encabezados de columna
      tableColumns.forEach((column, index) => {
        worksheet.getCell(1, index + 1).font = {
          bold: true,
          size: 12,
          color: { argb: 'FFFFFFFF' },
        };
        worksheet.getCell(1, index + 1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '16c7f9' },
        };
      });

      // Agregar filas de datos
      tableRows.forEach(row => {
        const rowData = tableColumns.map(column => {
          const value = column.selector(row);
          if (column.numFmt && column.numFmt !== '@') {
            return Number(value);
          }
          return value;
        });
        worksheet.addRow(rowData);
      });

      // Add black borders around all cells starting from the second row
      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        if (rowNumber > 1) {
          row.eachCell({ includeEmpty: true }, cell => {
            cell.border = {
              top: { style: 'thin', color: { argb: 'FF000000' } },
              left: { style: 'thin', color: { argb: 'FF000000' } },
              bottom: { style: 'thin', color: { argb: 'FF000000' } },
              right: { style: 'thin', color: { argb: 'FF000000' } },
            };
          });
        } else {
          worksheet.getRow(1).eachCell({ includeEmpty: true }, cell => {
            cell.border = {
              bottom: { style: 'thin', color: { argb: 'FF000000' } },
            };
          });
        }
      });
    });

    // Generar el archivo de Excel y descargarlo
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, filename + '.xlsx');
    });
  };

  //obtener todos los errores en las ventas
  const getErrores = async () => {
    setErrores([]);
    setLoadingErrores(true);

    const id_agente =
      JSON.parse(localStorage.empleado_selec) !== null
        ? JSON.parse(localStorage.empleado_selec).value
        : localStorage.getItem('user_id');

    let sucursalInfo = null;

    if (JSON.parse(localStorage.sucursal_selec) !== null) {
      if (localStorage.getItem('SucursalesInfo') == 0) {
        sucursalInfo = +JSON.parse(localStorage.sucursal_selec).value;
      }
    }

    let data = {
      periodo: moment(periodo_errores).format('MMMM YYYY').toUpperCase(),
      id_usuario: localStorage.getItem('user_id') || 0,
      //id_usuario: 15,
      id_sucursal: sucursalInfo,
      id_empleado:
        JSON.parse(localStorage.empleado_selec) !== null
          ? +JSON.parse(localStorage.empleado_selec).value
          : null,
    };
    const params = new URLSearchParams();
    params.append('periodo', data.periodo);
    params.append('id_usuario', data.id_usuario);
    params.append('id_sucursal', data.id_sucursal);
    params.append('id_empleado', data.id_empleado);

    const response = await request.get(
      `/reportes/get/errores?${params.toString()}`
    );
    if (response && !response.error) {
      if (response.errores && !response.empty) {
        setErrores(response.errores);
        //console.log(response.errores);
      } else {
        setErrores([]);
        setLoadingErrores(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
    setLoadingErrores(false);
  };

  //obtener todas las comisiones
  const getComisiones = async () => {
    setComisiones([]);
    setLoadingComisiones(true);

    const id_agente =
      JSON.parse(localStorage.empleado_selec) !== null
        ? JSON.parse(localStorage.empleado_selec).value
        : localStorage.getItem('user_id');

    let sucursalInfo = null;

    if (JSON.parse(localStorage.sucursal_selec) !== null) {
      if (localStorage.getItem('SucursalesInfo') == 0) {
        sucursalInfo = +JSON.parse(localStorage.sucursal_selec).value;
      }
    }

    let data = {
      periodo: moment(periodo_comisiones).format('MMMM YYYY').toUpperCase(),
      id_usuario: localStorage.getItem('user_id') || 0,
      id_sucursal: sucursalInfo,
      id_empleado:
        JSON.parse(localStorage.empleado_selec) !== null
          ? +JSON.parse(localStorage.empleado_selec).value
          : null,
    };

    const response = await request.post('/reportes/get/comisiones/', data);
    if (response && !response.error) {
      if (response.comisiones && !response.empty) {
        setComisiones(response.comisiones);
        // console.log(response.comisiones);
      } else {
        setComisiones([]);
        setLoadingComisiones(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
    setLoadingComisiones(false);
  };

  //obtener los detalles de las comisiones
  const getComisionesDetalles = async () => {
    setComisionesDetalles([]);
    setLoadingComisionesDetalles(true);

    const id_agente =
      JSON.parse(localStorage.empleado_selec) !== null
        ? JSON.parse(localStorage.empleado_selec).value
        : localStorage.getItem('user_id');

    let sucursalInfo = null;

    if (JSON.parse(localStorage.sucursal_selec) !== null) {
      if (localStorage.getItem('SucursalesInfo') == 0) {
        sucursalInfo = +JSON.parse(localStorage.sucursal_selec).value;
      }
    }

    let data = {
      periodo: moment(periodo_comisiones).format('MMMM YYYY').toUpperCase(),
      id_usuario: localStorage.getItem('user_id') || 0,
      id_sucursal: sucursalInfo,
      id_empleado:
        JSON.parse(localStorage.empleado_selec) !== null
          ? +JSON.parse(localStorage.empleado_selec).value
          : null,
    };

    const response = await request.post(
      '/reportes/get/comisiones/detalles',
      data
    );
    if (response && !response.error) {
      if (response.comisiones && !response.empty) {
        setComisionesDetalles(response.comisiones);
        setComisionesTableData(
          Object.keys(columnas_comisiones_resumen).map(key => ({
            columna1: columnas_comisiones_resumen[key],
            columna2:
              response.comisiones && response.comisiones.length > 0
                ? response.comisiones[0][key]
                : '',
          }))
        );
        console.log(response.comisiones);
      } else {
        setComisionesDetalles([]);
        setLoadingComisionesDetalles(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
    setLoadingComisionesDetalles(false);
  };

  //obtener todas las ventas
  const getVentas = async () => {
    setVentas([]);
    setLoadingVentas(true);

    const id_agente =
      JSON.parse(localStorage.empleado_selec) !== null
        ? JSON.parse(localStorage.empleado_selec).value
        : localStorage.getItem('user_id');

    let sucursalInfo = 0;

    if (JSON.parse(localStorage.sucursal_selec) !== null) {
      if (localStorage.getItem('SucursalesInfo') == 0) {
        sucursalInfo = +JSON.parse(localStorage.sucursal_selec).value;
      }
    }

    let periodo = moment(periodo_ventas);
    let data = {
      id_sucursal: sucursalInfo,
      id_agente: id_agente || 0,
      fecha_inicio: periodo
        .startOf('month')
        .format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)'),
      fecha_fin: periodo
        .endOf('month')
        .format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)'),
    };

    const response = await request.post('/ventas/get/all/', data);
    if (response && !response.error) {
      if (response.ventas && !response.empty) {
        setVentas(response.ventas);
      } else {
        setVentas([]);
        setLoadingVentas(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
    setLoadingVentas(false);
  };

  useEffect(() => {
    getErrores();
    getVentas();
    getComisiones();
    getComisionesDetalles();
  }, []);

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Reportes" parent="General" title="Reportes" />
      {/*Errores */}
      <Container fluid={true} className="chart-widget">
        <Row>
          <Col sm="12">
            <Card style={{ minHeight: '65vh' }}>
              <HeaderCard title="Error datos" />

              <CardBody style={{ paddingTop: '0px' }}>
                <Row
                  className="justify-content-end"
                  style={{ paddingBottom: '20px' }}
                >
                  <Col sm="3">
                    <Row style={{ paddingTop: '25px' }}>
                      <Label className="col-sm-6 col-form-label text-end">
                        Período:
                      </Label>

                      <Col sm="6">
                        {' '}
                        <ReactDatePicker
                          className="form-control digits"
                          selected={periodo_errores}
                          maxDate={new Date()}
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                          locale="en"
                          onChange={date => {
                            setPeriodoErrores(date);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col sm="3">
                    <Row
                      className="justify-content-end"
                      style={{ paddingTop: '20px' }}
                    >
                      <Col xs="6" md="6" xl="12">
                        <Btn
                          attrBtn={{
                            style: {
                              width: 86,
                              fontSize: 15,
                              padding: 6,
                              marginRight: '10px',
                              marginTop: '5px',
                            },
                            color: 'info',
                            className: 'btn btn-xs',
                            type: 'button',
                            onClick: () => {
                              getErrores();
                            },
                          }}
                        >
                          <i className="fa fa-check">
                            {' '}
                            <span style={{ fontFamily: 'Rubik, sans-serif' }}>
                              Aplicar
                            </span>
                          </i>
                        </Btn>

                        <Btn
                          attrBtn={{
                            style: {
                              width: 86,
                              fontSize: 15,
                              padding: 6,
                              marginRight: '10px',
                              marginTop: '5px',
                            },
                            color: 'info',
                            className: 'btn btn-xs m-t-5',
                            type: 'button',
                            onClick: () => {
                              exportToExcel(
                                [
                                  {
                                    tableColumns: tableColumns_errores,
                                    tableRows: errores,
                                  },
                                ],
                                'Errores-' +
                                  moment(periodo_errores).format('MMMM YYYY')
                              );
                            },
                          }}
                        >
                          <i className="fa fa-file-excel-o ">
                            {' '}
                            <span style={{ fontFamily: 'Rubik, sans-serif' }}>
                              Excel
                            </span>
                          </i>
                        </Btn>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <DataTableComponent
                  tableColumns={tableColumns_errores}
                  tableRows={errores}
                  loadingVentas={loadingErrores}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {/*Ventas mensuales */}
      <Container fluid={true} className="chart-widget">
        <Row>
          <Col sm="12">
            <Card style={{ minHeight: '65vh' }}>
              <HeaderCard title="Ventas mensuales" />

              <CardBody style={{ paddingTop: '0px' }}>
                <Row
                  className="justify-content-end"
                  style={{ paddingBottom: '20px' }}
                >
                  <Col sm="3">
                    <Row style={{ paddingTop: '25px' }}>
                      <Label className="col-sm-6 col-form-label text-end">
                        Período:
                      </Label>

                      <Col sm="6">
                        {' '}
                        <ReactDatePicker
                          className="form-control digits"
                          selected={periodo_ventas}
                          maxDate={new Date()}
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                          locale="en"
                          onChange={date => {
                            setPeriodoVentas(date);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col sm="3">
                    <Row
                      className="justify-content-end"
                      style={{ paddingTop: '20px' }}
                    >
                      <Col xs="6" md="6" xl="12">
                        <Btn
                          attrBtn={{
                            style: {
                              width: 86,
                              fontSize: 15,
                              padding: 6,
                              marginRight: '10px',
                              marginTop: '5px',
                            },
                            color: 'info',
                            className: 'btn btn-xs',
                            type: 'button',
                            onClick: () => {
                              getVentas();
                            },
                          }}
                        >
                          <i className="fa fa-check">
                            {' '}
                            <span style={{ fontFamily: 'Rubik, sans-serif' }}>
                              Aplicar
                            </span>
                          </i>
                        </Btn>

                        <Btn
                          attrBtn={{
                            style: {
                              width: 86,
                              fontSize: 15,
                              padding: 6,
                              marginRight: '10px',
                              marginTop: '5px',
                            },
                            color: 'info',
                            className: 'btn btn-xs',
                            type: 'button',
                            onClick: () => {
                              exportToExcel(
                                [
                                  {
                                    tableColumns: tableColumns_ventas,
                                    tableRows: ventas,
                                  },
                                ],
                                'Ventas-' +
                                  moment(periodo_ventas).format('MMMM YYYY')
                              );
                            },
                          }}
                        >
                          <i className="fa fa-file-excel-o ">
                            {' '}
                            <span style={{ fontFamily: 'Rubik, sans-serif' }}>
                              Excel
                            </span>
                          </i>
                        </Btn>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <DataTableComponent
                  tableColumns={tableColumns_ventas}
                  tableRows={ventas}
                  loadingVentas={loadingVentas}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {/*Comisiones*/}
      <Container fluid={true} className="chart-widget">
        <Row>
          <Col sm="12">
            <Card style={{ minHeight: '65vh' }}>
              <HeaderCard title="Comisiones" />

              <CardBody style={{ paddingTop: '0px' }}>
                <Row
                  className="justify-content-end"
                  style={{ paddingBottom: '20px' }}
                >
                  <Col sm="3">
                    <Row style={{ paddingTop: '25px' }}>
                      <Label className="col-sm-6 col-form-label text-end">
                        Período:
                      </Label>

                      <Col sm="6">
                        {' '}
                        <ReactDatePicker
                          className="form-control digits"
                          selected={periodo_comisiones}
                          maxDate={new Date()}
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                          locale="en"
                          onChange={date => {
                            setPeriodoComisiones(date);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col sm="3">
                    <Row
                      className="justify-content-end"
                      style={{ paddingTop: '20px' }}
                    >
                      <Col xs="6" md="6" xl="12">
                        <Btn
                          attrBtn={{
                            style: {
                              width: 86,
                              fontSize: 15,
                              padding: 6,
                              marginRight: '10px',
                              marginTop: '5px',
                            },
                            color: 'info',
                            className: 'btn btn-xs',
                            type: 'button',
                            onClick: () => {
                              getComisiones();
                              getComisionesDetalles();
                            },
                          }}
                        >
                          <i className="fa fa-check">
                            {' '}
                            <span style={{ fontFamily: 'Rubik, sans-serif' }}>
                              Aplicar
                            </span>
                          </i>
                        </Btn>

                        <Btn
                          attrBtn={{
                            style: {
                              width: 86,
                              fontSize: 15,
                              padding: 6,
                              marginRight: '10px',
                              marginTop: '5px',
                            },
                            color: 'info',
                            className: 'btn btn-xs',
                            type: 'button',
                            onClick: () => {
                              exportToExcel(
                                [
                                  {
                                    tableColumns: tableColumns_comisiones,
                                    tableRows: comisiones,
                                  },
                                  {
                                    tableColumns:
                                      tableColumns_comisiones_ventas,
                                    tableRows: comisionesDetalles,
                                  },
                                  {
                                    tableColumns:
                                      tableColumns_comisiones_comisiones,
                                    tableRows: comisionesDetalles,
                                  },
                                  {
                                    tableColumns:
                                      tableColumns_comisiones_resumen,
                                    tableRows: comisionesTableData,
                                  },
                                ],
                                'Comisiones-' +
                                  moment(periodo_comisiones).format('MMMM YYYY')
                              );
                            },
                          }}
                        >
                          <i className="fa fa-file-excel-o ">
                            {' '}
                            <span style={{ fontFamily: 'Rubik, sans-serif' }}>
                              Excel
                            </span>
                          </i>
                        </Btn>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <DataTableComponent
                  tableColumns={tableColumns_comisiones}
                  tableRows={comisiones}
                  loadingVentas={loadingComisiones}
                />
                {/*  Detalles Comisiones */}
                <Row className="m-t-20">
                  <H6>Ventas</H6>
                  <DataTableComponent
                    tableColumns={tableColumns_comisiones_ventas}
                    tableRows={comisionesDetalles}
                    loadingVentas={loadingComisionesDetalles}
                    pagination={false}
                  />
                </Row>
                <Row className="m-t-20">
                  <H6>Comisiones</H6>
                  <DataTableComponent
                    tableColumns={tableColumns_comisiones_comisiones}
                    tableRows={comisionesDetalles}
                    loadingVentas={loadingComisionesDetalles}
                    pagination={false}
                  />
                </Row>
                <Row className="m-t-20">
                  <DataTableComponent
                    tableColumns={tableColumns_comisiones_resumen}
                    tableRows={comisionesTableData}
                    loadingVentas={loadingComisionesDetalles}
                    pagination={false}
                  />
                </Row>
                <span className="pull-right m-r-20">
                  Total Comisión:{' '}
                  {comisionesDetalles.length > 0 ? (
                    <div style={{ textAlign: 'right' }}>{`${parseFloat(
                      comisionesDetalles[0].total
                    ).toLocaleString('es-MX', {
                      style: 'currency',
                      currency: 'MXN',
                    })}`}</div>
                  ) : (
                    0
                  )}
                </span>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Reportes;
