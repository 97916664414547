/**
 *  urlApi.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Manejor de las ruta directas al API
 */

function url() {
   //API local
   let url = 'http://localhost:8081';

   if (process.env.NODE_ENV === 'production') {
      // API produccion
      url = 'https://api.ovd.mx';
   }

   return url;
}

export const URL_API = url();
