/**
 *  httpClient.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Dayron
 *  @description: Cliente HTTP para todas las peticiones Web
 */

import axios from "axios";
import { URL_API } from "./urlsApi";

class Request {
  async get(url) {
    const result = await axios
      .get(URL_API + url, {
        headers: {
          igate_key: "5fcae9a7be1dfa53da44c353492bc73d",
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: true, message: error.message };
      });
    return result;
  }

  async post(url, data) {
    const result = await axios
      .post(URL_API + url, data, {
        headers: {
          igate_key: "5fcae9a7be1dfa53da44c353492bc73d",
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: true, message: error.message };
      });
    return result;
  }

  async put(url, data) {
    const result = await axios
      .put(URL_API + url, data, {
        headers: {
          igate_key: "5fcae9a7be1dfa53da44c353492bc73d",
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return { error: true, message: error.message };
      });
    return result;
  }
}

export default Request;
