import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Col, FormGroup, Input, Label } from "reactstrap";
import { Btn, H5, P } from "../../../AbstractElements";
import ConfigurationClass from "../../../Layout/ThemeCustomizer/ConfigurationClass";
import Select from "react-select";
import { Image } from "react-feather";
//import { AddSale } from "../../Constant";
import sucursalesimg from "../../../assets/images/woman-laptop-online-shop.png";
import { padding } from "@mui/system";
import Request from "../../../api/httpClient";
const request = new Request();

const ChangeSucursalMenu = ({ callbackNavTab }) => {
  const [modal, setModal] = useState(false);
  const [sucursales, setSucursales] = useState([]);
  const [empleados, setEmpleados] = useState([]);
  const [loadingEmpleados, setLoadingEmpleados] = useState(false);
  const [sucursalSelect, setSucursalSelect] = useState(null);
  const [empleadoSelect, setEmpleadoSelect] = useState(null);
  const [userType, setUserType] = useState(localStorage.getItem("TypeID"));

  //0-sucursal seleccionada, 1-todas las sucursales
  const [sucursalesInfo, setSucursalesInfo] = useState(
    localStorage.getItem("SucursalesInfo") !== null
      ? localStorage.getItem("SucursalesInfo")
      : 1
  );
  const toggle = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  //obtener todos los empleados de la sucursal seleccionada
  const getEmpleados = async (id_sucursal) => {
    setEmpleados([]);
    setLoadingEmpleados(true);

    let data = {
      id_sucursal: id_sucursal || 0,
    };

    const response = await request.post("/sucursales/get/empleados/all/", data);
    if (response && !response.error) {
      if (response.empleados && !response.empty) {
        const empleados = response.empleados.map((empleado) => ({
          value: empleado.Id,
          label: empleado.Nombre,
        }));
        setEmpleados(empleados);
        const empleadoSeleccionado = JSON.parse(localStorage.empleado_selec);
        if (empleadoSeleccionado !== null) {
          if (
            empleados.some(
              (empleado) => empleado.value === empleadoSeleccionado.value
            )
          ) {
            setEmpleadoSelect(empleadoSeleccionado);
          } else {
            setEmpleadoSelect(null);
          }
        } else {
          setEmpleadoSelect(null);
        }
      } else {
        setEmpleados([]);
        setLoadingEmpleados(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
    setLoadingEmpleados(false);
  };

  useEffect(() => {
    if (localStorage.getItem("sucursales")) {
      const sucursales = JSON.parse(localStorage.sucursales);
      setSucursales(sucursales);
      const sucursalSelec =
        JSON.parse(localStorage.sucursal_selec) !== null
          ? JSON.parse(localStorage.sucursal_selec)
          : sucursales[0];
      setSucursalSelect(sucursalSelec);
      if (userType !== "0") getEmpleados(sucursalSelec.value);
    }
  }, []);

  useEffect(() => {
    //console.log("me ejecute");
    localStorage.setItem("SucursalesInfo", sucursalesInfo);
  }, [sucursalesInfo]);

  const refreshPage = () => {
    window.location.reload();
  };

  const CustomLoader = () => (
    <div className="loader-box">
      <div className="loader-15" />
    </div>
  );

  return (
    <Fragment>
      <div>
        <div className="customizer-header">
          <i
            className="icon-close"
            onClick={() => callbackNavTab("", false)}
          ></i>
          <H5>{"Mis sucursales"}</H5>
          <P attrPara={{ className: "mb-0" }}>
            {"Cambiar de sucursal"}{" "}
            <i className="fa fa-building-o" style={{ color: "#4069d0" }}></i>
          </P>
          <ConfigurationClass modal={modal} toggle={toggle} />
        </div>
        <div style={{ padding: "0px 25px" }}>
          <Select
            options={sucursales}
            placeholder={"Cambiar..."}
            value={sucursalSelect}
            onChange={(e) => {
              setSucursalSelect(e);
              if (userType !== "0") getEmpleados(e.value);
              //console.log(e);
            }}
          />
          {loadingEmpleados ? (
            <CustomLoader />
          ) : (
            userType !== "0" &&
            sucursalesInfo == 0 && (
              <div style={{ paddingTop: "25px" }}>
                <H5>{"Ejecutivos"}</H5>
                <P attrPara={{ className: "mb-0" }}>
                  {"Elegir un ejecutivo"}{" "}
                  <i className="fa fa-user-o" style={{ color: "#4069d0" }}></i>
                </P>
                <div style={{ paddingTop: "10px" }}>
                  <Select
                    options={empleados}
                    placeholder={"Elegir..."}
                    value={empleadoSelect}
                    isClearable={true}
                    onChange={(e) => {
                      setEmpleadoSelect(e);
                      // console.log(e);
                    }}
                  />
                </div>
              </div>
            )
          )}

          <div style={{ paddingTop: "25px" }}>
            <H5>{"Dashboard"}</H5>
            <P attrPara={{ className: "mb-0" }}>
              {"Mostrar información de: "}{" "}
              {/* <i className="fa fa-info" style={{ color: "#4069d0" }}></i> */}
            </P>
            <div style={{ paddingTop: "10px" }}>
              <div>
                <div className="radio radio-primary p-l-10">
                  <Input
                    id="radio_sucursal"
                    type="radio"
                    name="radio_sucursal"
                    checked={sucursalesInfo == 0}
                    onChange={() => {
                      setSucursalesInfo(0);
                    }}
                  />
                  <Label for="radio_sucursal">Sucursal seleccionada</Label>
                </div>
                <div className="radio radio-primary p-l-10">
                  <Input
                    id="radio_sucursal_todas"
                    type="radio"
                    name="radio_sucursal_todas"
                    checked={sucursalesInfo == 1}
                    onChange={() => {
                      setSucursalesInfo(1);
                      setEmpleadoSelect(null);
                    }}
                  />
                  <Label for="radio_sucursal_todas">Todas las sucursales</Label>
                </div>
              </div>
            </div>
          </div>

          <div
            className="cartoon"
            style={{ padding: "5px", textAlign: "center" }}
          >
            <img
              className="img-fluid"
              src={sucursalesimg}
              alt=""
              style={{ width: "20vh" }}
            />
          </div>
          <Btn
            attrBtn={{
              color: "primary",
              onClick: () => {
                localStorage.setItem(
                  "sucursal_selec",
                  JSON.stringify(sucursalSelect)
                );
                localStorage.setItem(
                  "empleado_selec",
                  JSON.stringify(empleadoSelect)
                );
                //console.log(empleadoSelect);
                refreshPage();
              },
              style: { float: "right" },
            }}
          >
            Aplicar
          </Btn>
        </div>
      </div>
    </Fragment>
  );
};

export default ChangeSucursalMenu;
