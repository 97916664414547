import React, { useContext, useState } from 'react';
import { Grid } from 'react-feather';
import { Link } from 'react-router-dom';
import { Image } from '../../AbstractElements';
import OVDIcon from '../../assets/images/login/ovd_logo.svg';
import CustomizerContext from '../../_helper/Customizer';

const SidebarLogo = () => {
  const { mixLayout, toggleSidebar, layout, layoutURL } =
    useContext(CustomizerContext);
  const [toggle, setToggle] = useState(false);

  const openCloseSidebar = () => {
    setToggle(!toggle);
    toggleSidebar(toggle);
  };

  const layout1 = localStorage.getItem('sidebar_layout') || layout;

  return (
    <div
      className="logo-wrapper"
      style={{ height: '80px', textAlign: 'center', paddingBottom: '10px' }}
    >
      {layout1 !== 'compact-wrapper dark-sidebar' &&
      layout1 !== 'compact-wrapper color-sidebar' &&
      mixLayout ? (
        <Link to={'/'}>
          <Image
            attrImage={{
              className: 'img-fluid d-inline',
              src: `${OVDIcon}`,
              alt: '',
            }}
          />
        </Link>
      ) : (
        <Link to={'/'}>
          <Image
            attrImage={{
              className: 'img-fluid d-inline',
              src: `${OVDIcon}`,
              alt: '',
            }}
          />
        </Link>
      )}
      <div className="back-btn" onClick={() => openCloseSidebar()}>
        <i className="fa fa-angle-left"></i>
      </div>
      {/*  <div className="toggle-sidebar" onClick={openCloseSidebar}>
        <Grid className="status_toggle middle sidebar-toggle" />
      </div> */}
    </div>
  );
};

export default SidebarLogo;
